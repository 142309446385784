@import '../styles.scss';

.container{
    padding: 80px 0;
    background:
    linear-gradient(90deg, $primaryDark (22px - 1px), transparent 1%) center,
    linear-gradient($primaryDark (22px - 1px), transparent 1%) center,
    $fontLight;
    background-size: 22px 22px;
    width: 100%;
    text-align: center;
    text-align: -webkit-center;
    color: #F6F6F9;
   
    h2{
        padding-bottom: 50px;margin-bottom: 20px;
        text-align: center;
        text-align: -webkit-center;
        color: $fontLight;
    }

    .info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 670px;
        width: 100%;
        text-align: center;
        margin: 50px 0;

        .wrap{
            width: 170px;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            justify-content: center;
            margin: 15px 0;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            
            .icon{
                max-width: 100px;
                width: 100%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
             
            }

            h4{
                font-size: 1em;
                font-weight: 700;
                color: $fontLight;
            }
        }
    }
 
}
@media screen and (min-width: $screenmd){
        .container{
            h2{
                font-size: 1.7em;
                text-align: inherit;
            }

            .itemContainer{
                display: flex;
                flex-direction: row; 
                justify-content: space-between;
                align-items: center;

                .formContainer{
                    max-width: 600px;
                    width: 100%;
                }
            } 
    }
}
