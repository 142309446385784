// Colors

$primaryBright: #7077FD;

$primaryDark: #222222;
$secondaryDark: #2d2f3a;

$primaryLight: #292933;
$secondaryLight: #2F2F39;

$fontLight: #F6F6F9;