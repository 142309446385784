@import '../styles.scss';

.fadeInSection{
    opacity: 0;
    transform: translateY(15px);
    visibility: hidden;
    transition: opacity 1000ms ease-out, transform 500ms ease-out, visibility 1000ms ease-out;
    will-change: opacity, transform, visibility;

    &.fade{
        opacity: 1;
        transform: none;
        visibility: visible;
    
    }
    
}
