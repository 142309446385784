@import '../styles.scss';

footer{
    width: 100%;
    background: linear-gradient(90deg, $primaryDark 0%, rgba(109, 110, 112, 0.521) 100%);
    display: flex;
    justify-content: center;

    .container{
        width: 100%;

        .info{
            max-width: 1400px;
            width: 100%;
            margin: 0 auto;
            height: auto;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 300px;
            justify-content: center;

            .content{
                max-width: 600px;
                width: 100%;
                margin: 20px;

                h3, p {
                    color: white;
                }
                h3{
                    font-size: 1.7em;
                }
                p{
                    font-weight: 300;
                    font-size: 1em;
                    margin: 20px 0;
                }

            }
           
            .btnWrap{
                text-align: center;
                margin: 20px 0px;
                max-width: 600px;
                width: 100%;
                text-align: -webkit-center;

                .btn{
                    @include primary-btn;
                } 
            }
            
        }
        .footerLogo{
            text-align: -webkit-center;
            text-align: center;
            background-color: $primaryBright;

            img{
                max-width: 30px;
                width: 100%;
                margin: 10px 0;
            }   

        }
    
    }

}

@media screen and (min-width: $screenmd){
  footer{
      .container{
          .info{
              flex-direction: row;
              justify-content: space-between;

          }
      }
  }
}

@media screen and (min-width: $screenlg){
  footer{
      .container{
          .info{
              flex-direction: row;
              justify-content: space-between;

              .content {
                h3{
                    font-size: 2em;
                }
                p{
                    font-weight: 300;
                    font-size: 1.3em;
                    margin: 20px 0;
                }
              }
          }
      }
  }
}