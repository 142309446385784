@import '../styles.scss';

.container{
    display: flex;
    flex-direction: column;
    color: $primaryDark;
    min-height: calc(100vh - 120px);
    margin-top: 50px;

    .info{
        max-width: 650px;
        width: 100%;
        height: 100%;
       
        h1{
            color: $fontLight; 
        }

        p{
            font-size: 1.17em;
            font-weight: 300;
            margin: 40px 0;
            color: $fontLight;
        }

        .btn{
            @include primary-btn;
            line-height: 5em;
        }
  
    }

    .heroImg{
        max-width: 600px;
        width: 100%;
        height: 100%;
        margin: 20px 0;
        margin-left: auto;
        margin-right: auto;
        
        img{
            @include drop-shadow;
        }

    }

    .backgroundGradient{
        background: $primaryDark;
        background: linear-gradient(90deg, $primaryDark 0%, rgba(141, 144, 148, 0.644) 100%);
        width: 100%;
        height: 98%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -5;
    }

    .wave{
        position: absolute;
        display: block;
        width: 100%;
        height: auto;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

}
@media screen and (min-width: $screenmd){
    .container{
        margin-top: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        .info{
            padding: 20px 20px 20px 0;
        }
  
    }
}

@media screen and (min-width: $screenlg){
    .container{
        .info{
            h1{
                font-size: 1.9em;
            }
    
        }
    }
}
