@import '../styles.scss';

.container{
    padding: 80px 0;

    .itemOne, .itemTwo, .itemThree{
        display: flex;
        flex-direction: column;
        padding: 70px 5px;
        align-items: center;

        .imgWrap{
            max-width: 500px;
            height: auto;
            background:
            linear-gradient(90deg, $primaryDark (22px - 1px), transparent 1%) center,
            linear-gradient($primaryDark (22px - 1px), transparent 1%) center,
            $fontLight;
            background-size: 22px 22px;
            img{
               @include drop-shadow; 
            }
          
        }
        .itemContent{
            max-width: 500px;
            width: 100%;
            padding: 40px 10px;

            h4, p{
                color: $fontLight;
            }

            h4{
                font-size: 1.3em;
                margin: 20px 0;
                position: relative;

                &::before{
                    content: "";
                    width: 100px;
                    height: 100px;
                    display: block;
                    position: absolute;
                    top: -45px;
                    left: -45px;
                    border: 5px $secondaryLight solid;
                    z-index: -1;
                }
            }

            p{
                font-size: 1em;
                font-weight: 300;
                margin: 10px 0;
            }
        }
    }
}

@media screen and (min-width: $screenmd){
    .container{
        .itemOne, .itemTwo, .itemThree{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .itemContent{
                margin: 20px;
                
                h4{
                    font-size: 1.7em;
                }
                p{
                    font-size: 1.1em;
                }
            }

            .imgWrap{
                margin: 20px;
            }
        }
        .itemTwo{
            flex-direction: row-reverse;
        }
    }
}

@media screen and (min-width: $screenlg){
    .container{
        .itemOne, .itemTwo, .itemThree{
  
            .itemContent{
                h4{
                    font-size: 1.95em;
                }
                p{
                    font-size: 1.19em;
                }
            }
          
        }
       
    }
}